import Image from "next/image";
import logo from "@book-the-play/public/logo/color.svg";

export const Loading = ({
  heightClass = "wb-h-dvh",
}: {
  heightClass?: string;
}) => (
  <section
    className={`wb-w-full wb-flex wb-justify-center wb-items-center wb-mt-20 ${heightClass}`}
  >
    <div className="wb-h-28">
      <Image
        src={logo}
        width={100}
        height={80}
        alt="loading"
        className="loading-img wb-h-full"
      />
    </div>
  </section>
);
