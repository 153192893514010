import React from "react";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import { FADE_TO_SHOW_ANIMATION } from "@libs/utils/animation";
import { FeatureList } from "./FeatureList";
import {
  leftEndingPoint,
  leftStartingPoint,
  rightEndingPint,
} from "@book-the-play/utils/constant";

export const KeyFeatures = () => {
  return (
    <section
      id="key-features"
      className="wb-m-0 wb-py-12 lg:py-5 wb-bg-gray-100"
    >
      <div className="main-container">
        <div className="wb-flex wb-items-center wb-flex-initial">
          <div className="wb-flex wb-flex-col">
            <Transition
              className="wb-text-center wb-hidden md:wb-block"
              transition={{
                duration: 0.5,
              }}
              variants={FADE_TO_SHOW_ANIMATION}
            >
              <Typography tag="h2" className="wb-text-[2.4rem] wb-mb-4">
                Key Features
              </Typography>
              <Typography
                tag="p"
                className="wb-font-medium wb-text-xl wb-leading-8 wb-tracking-wide"
              >
                One-stop platform for booking slots for your preferred sports
                and games
              </Typography>
            </Transition>
            <div className="wb-text-center md:wb-hidden wb-block">
              <Typography tag="h2" className="wb-text-[2.4rem] wb-mb-4">
                Key Features
              </Typography>
              <Typography
                tag="p"
                className="wb-font-medium wb-text-xl wb-leading-8 wb-tracking-wide"
              >
                One-stop platform for booking slots for your preferred sports
                and games
              </Typography>
            </div>
            <div className="wb-flex wb-gap-10 wb-my-10 mlg:wb-flex-col mlg:wb-gap-0">
              <FeatureList
                startingIndex={leftStartingPoint}
                endingIndex={leftEndingPoint}
                listShowClassName="wb-flex-row-reverse mlg:wb-flex-row"
                descriptionBlockClassName="wb-text-end mlg:wb-text-start"
              />
              <FeatureList
                startingIndex={leftEndingPoint}
                endingIndex={rightEndingPint}
                descriptionBlockClassName="wb-text-start"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
