import { Typography } from "@libs/src/components/Typography";
import Link from "next/link";
import Image from "next/image";
import phoneIcon from "@book-the-play/public/img/svg/phoneIcon.svg";
import mailIcon from "@book-the-play/public/img/svg/mailIcon.svg";
import "./styles/contactUs.scss";

export const ContactUs = () => {
  const smallIconWidth = 25;
  const smallIconHeight = 25;

  return (
    <section className="main-container get-in-touch-section" id="contact-us">
      <Typography tag="h3" className="wb-text-3xl">
        Get In Touch
      </Typography>
      <div className="grid-section">
        <div className="heading-text-block">
          <Typography tag="h4">Address</Typography>
          <Typography tag="p">
            First Floor, Studio Complex, Gota, Ahmedabad, <br /> Gujarat, 382481
          </Typography>
        </div>
        <div className="heading-text-block">
          <Typography tag="h4">Business Hours</Typography>
          <Typography tag="p">
            Mon - Fri : 9:00 AM - 6:00 PM <br />
            Sat - Sun : Closed
          </Typography>
        </div>
        <div className="heading-text-block">
          <Typography tag="h4">Contact/Inquiry</Typography>
          <div className="wb-flex wb-flex-col wb-gap-2">
            <div className="wb-flex wb-gap-2 wb-items-center">
              <Image
                src={phoneIcon}
                alt="phone-icon"
                height={smallIconHeight}
                width={smallIconWidth}
              />
              <Typography tag="p">+91 9033291981</Typography>
            </div>
            <div className="wb-flex wb-gap-2 wb-items-center">
              <Image
                src={mailIcon}
                alt="mail-icon"
                height={smallIconHeight}
                width={smallIconWidth}
              />
              <Link href="mailto:info@webelight.co.in" className="wb-text-sm">
                info@webelight.co.in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
