import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { FADE_TO_SHOW_CIRCLE_ANIMATION } from "@libs/utils/animation";
import { veryLargeHeight, veryLargeWidth } from "@book-the-play/utils/constant";
import "./styles/background.scss";

export const BackgroundImage = () => {
  return (
    <>
      <Transition
        className="custom-el custom-el-circle wb-border-secondary wb-top-[49%] wb-left-[87%] wb-w-[15px] wb-h-[15px]"
        variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
      />
      <Transition
        className="custom-el custom-el-circle custom-el-blur-1 wb-border-primary wb-top-[58%] wb-left-[80%] wb-w-[48px] wb-h-[48px]"
        variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
      />
      <Transition
        className="custom-el custom-el-rounded-rectangle custom-rotate-45 custom-el-blur-2 wb-border-primary wb-top-[96%] wb-left-[78%] wb-w-[27px] wb-h-[27px]"
        variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
      />
      <div className="custom-svg-3">
        <Image
          width={veryLargeWidth}
          height={veryLargeHeight}
          src="img/demos/app-landing/svg/shape-1.svg"
          alt="shape-1"
          data-icon=""
        />
      </div>
    </>
  );
};
