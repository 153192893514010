import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import { Typography } from "@libs/src/components/Typography";
import { WhyBecomePartner } from "./WhyBecomePartner";
import { YouTubeVideoSection } from "@book-the-play/modules/components/YouTubeVideo/YouTubeVideoSection";
import PartnerForm from "./FormHOC";

export const BecomeAPartner = () => {
  return (
    <>
      <section
        id="become-a-partner"
        className="wb-py-3 wb-m-0 lg:wb-py-16 wb-relative"
      >
        <div className="main-container">
          <div className="wb-flex wb-items-center mlg:wb-flex-col">
            <div className="wb-text-center lg:wb-text-start wb-w-[50%] mlg:wb-w-full">
              <Image
                src="/img/BTP-moklups-mobile/how-it-works-2.png"
                className="wb-mb-4 lg:wb-mb-0"
                alt="how-it-works-2"
                width={546}
                height={734}
              />
            </div>
            <div className="wb-text-center wb-w-[50%] lg:wb-text-start mlg:wb-w-full">
              <Transition
                transition={{
                  duration: 0.5,
                }}
                variants={DIRECTION_VARIANTS.FROM_LEFT}
                className="wb-hidden md:wb-block"
              >
                <Typography tag="h2" className="wb-text-[2.4rem] wb-mb-5">
                  Become a Partner
                </Typography>
                <Typography
                  tag="p"
                  className="wb-text-lg wb-leading-8 wb-tracking-wide"
                >
                  Are you a sports facility, event organizer, or service
                  provider looking to expand your reach and connect with a
                  dynamic community of sports enthusiasts?
                </Typography>
                <Typography tag="p" className="wb-text-base">
                  Let's collaborate to bring the joy of sports and activities to
                  a broader audience!
                </Typography>
              </Transition>
              <div className="md:wb-hidden wb-block">
                <Typography tag="h2" className="wb-text-[2.4rem] wb-mb-5">
                  Become a Partner
                </Typography>
                <Typography
                  tag="p"
                  className="wb-font-semibold wb-text-lg wb-leading-8 wb-tracking-wide"
                >
                  Are you a sports facility, event organizer, or service
                  provider looking to expand your reach and connect with a
                  dynamic community of sports enthusiasts?
                </Typography>
                <Typography tag="p" className="wb-text-base">
                  Let's collaborate to bring the joy of sports and activities to
                  a broader audience!
                </Typography>
              </div>
              <Typography tag="h4" className="wb-mt-16 wb-font-normal">
                Why Partner with Book The Play?
              </Typography>
              <WhyBecomePartner
                firstImagePath="/img/homepage/audience.svg"
                firstImageAlt="audience"
                firstTitle="Exposure to a Growing Audience"
                secondImagePath="/img/homepage/booking.svg"
                secondImageAlt="booking"
                secondTitle="Boost Your Bookings"
              />
              <WhyBecomePartner
                firstImagePath="/img/homepage/partnershipplans.svg"
                firstImageAlt="partnershipplans"
                firstTitle="Customized Partnership Plans"
                secondImagePath="/img/homepage/visibility.svg"
                secondImageAlt="visibility"
                secondTitle="Prominent Brand Visibility"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <YouTubeVideoSection />
      </section>
      <section className="wb-py-[50px] wb-px-0 wb-mb-[30px] wb-mx-0 wb-bg-gray-100">
        <Typography tag="h2" className="wb-text-center">
          List Your Business
        </Typography>
        <div className="main-container wb-my-5">
          <PartnerForm />
        </div>
      </section>
    </>
  );
};
