import Link from "next/link";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import { routes } from "@book-the-play/utils/route";
import { OverviewSwiper } from "./OverviewSwiper";
import { Playstore } from "./Playstore";
import "./styles/overview.scss";

export const Overview = () => {
  return (
    <>
      <section
        id="overview"
        className="wb-pt-20 wb-pb-14 wb-m-0 wb-bg-transparent wb-border-0 wb-relative"
      >
        <div className="main-container lg:wb-pt-5">
          <div className="wb-flex wb-pt-10 wb-mt mlg:wb-flex-col mlg:wb-items-center">
            <div className="left-section-container">
              <Transition
                as="h2"
                className="wb-mb-0 wb-text-primary wb-text-lg lg:wb-mt-12 lg:wb-pt-12 wb-uppercase wb-leading-5 wb-font-bold wb-tracking-widest wb-relative wb-hidden md:wb-block"
                variants={DIRECTION_VARIANTS.FROM_BOTTOM}
                transition={{
                  duration: 0.5,
                }}
              >
                BOOK. PLAY. CONNECT - All In One Click!
              </Transition>
              <Typography
                tag="h2"
                className="wb-mb-0 wb-text-primary wb-text-lg lg:wb-mt-12 lg:wb-pt-12 wb-uppercase wb-leading-5 wb-font-bold wb-tracking-widest wb-relative md:wb-hidden wb-block"
              >
                BOOK. PLAY. CONNECT - All In One Click!
              </Typography>
              <Transition
                as="h1"
                className="wb-pt-6 wb-pb-4 wb-text-dark wb-relative wb-text-[70px] wb-font-bold mmd:wb-text-[50px] wb-hidden md:wb-block"
                variants={DIRECTION_VARIANTS.FROM_BOTTOM}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                }}
              >
                <Typography tag="span" className="wb-relative ">
                  Book The Play
                </Typography>
                <Typography tag="span" className="custom-stroke-text-effect">
                  APP
                </Typography>
              </Transition>
              <Typography
                tag="h1"
                className="wb-pt-6 wb-pb-4 wb-text-dark wb-relative wb-text-[70px] wb-font-bold mmd:wb-text-[50px] md:wb-hidden wb-block"
              >
                <Typography tag="span" className="wb-relative ">
                  Book The Play
                </Typography>
              </Typography>
              <Transition
                as="p"
                className="wb-mb-4 wb-font-medium wb-text-xl wb-hidden md:wb-block"
                variants={DIRECTION_VARIANTS.FROM_BOTTOM}
                transition={{
                  duration: 0.5,
                  delay: 0.4,
                }}
              >
                Elevate Your Play, Elevate Your Lifestyle.
              </Transition>
              <Typography
                tag="p"
                className="wb-mb-4 wb-font-medium wb-text-xl md:wb-hidden wb-block"
              >
                Elevate Your Play, Elevate Your Lifestyle.
              </Typography>
              <Transition
                className="wb-w-full wb-py-10 wb-hidden md:wb-block"
                variants={DIRECTION_VARIANTS.FROM_BOTTOM}
                transition={{
                  duration: 0.5,
                  delay: 0.6,
                }}
              >
                <Link href={routes.downloadsPage} className="app-button">
                  GET THE APP
                </Link>
              </Transition>
              <div className="wb-w-full wb-py-10 md:wb-hidden wb-block">
                <Link href={routes.downloadsPage} className="app-button">
                  GET THE APP
                </Link>
              </div>
            </div>
            <div className="right-section-container">
              <OverviewSwiper />
            </div>
          </div>
        </div>
      </section>
      <Playstore />
    </>
  );
};
