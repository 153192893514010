import Link from "next/link";
import Image from "next/image";
import { Typography } from "@libs/src/components/Typography";
import {
  bookThePlayStoreButton,
  extraSmallHeight,
  extraSmallWidth,
} from "@book-the-play/utils/constant";
import "./styles/downloads.scss";

export const Downloads = () => {
  return (
    <section id="downloads" className="download-main-section">
      <div className="main-container">
        <div className="wb-py-12 wb-relative wb-flex mlg:wb-flex-col">
          <div className="wb-text-center lg:wb-text-start wb-w-[75%] mlg:wb-w-full">
            <Typography
              tag="h2"
              className="wb-mb-10 wb-text-light wb-text-[2.4rem] wb-tracking-normal"
            >
              Play More, Live More with Book The Play.
            </Typography>
            <Typography
              tag="p"
              className="wb-text-light wb-text-2xl wb-font-semibold wb-tracking-normal wb-leading-8"
            >
              Discover Playgrounds, Sports Venue, Turfs and more.
            </Typography>
            <Typography
              tag="p"
              className="wb-text-light wb-text-[1.1rem] wb-font-medium wb-leading-8 wb-opacity-60"
            >
              Book cricket grounds, football turfs, badminton & tennis courts
              near you.
            </Typography>
            <div className="wb-flex wb-gap-5 wb-py-14 mlg:wb-justify-center">
              {bookThePlayStoreButton.map(
                ({
                  id,
                  alternateName,
                  srcPath,
                  storeName,
                  socialLink,
                  imageClassName,
                }) => {
                  return (
                    <Link
                      className="play-store-btn"
                      href={socialLink}
                      target="_blank"
                      key={id}
                    >
                      <Image
                        height={extraSmallWidth}
                        width={extraSmallHeight}
                        src={srcPath}
                        alt={alternateName}
                        className={imageClassName}
                      />
                      <Typography
                        tag="span"
                        className="wb-font-semibold wb-text-base wb-flex wb-flex-col"
                      >
                        <Typography
                          tag="span"
                          className="wb-uppercase wb-font-medium wb-opacity-[0.5] wb-block wb-relative wb-text-xs"
                        >
                          Download On
                        </Typography>
                        <Typography
                          tag="span"
                          className="wb-text-dark wb-relative wb-text-lg"
                        >
                          {storeName}
                        </Typography>
                      </Typography>
                    </Link>
                  );
                },
              )}
            </div>
          </div>
          <div className="wb-pt-5 wb-text-center lg:wb-pt-0 wb-w-[25%] mlg:wb-w-full wb-flex mlg:wb-justify-center">
            <Image
              src="/img/BTP-moklups-mobile/splash-screen-mockup.png"
              alt="splash-screen-mockup"
              width={280}
              height={280}
              className="wb-mt-10 lg:wb-mb-0 minmd:wb-absolute minmd:-wb-top-[35%] minmd:-wb-right-[25px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
