import React from "react";
import Image from "next/image";
import { Typography } from "@libs/src/components/Typography";
import { FrequentlyAskQuestionAccordion } from "./FrequentlyAskQuestionAccordion";
import { veryLargeHeight, veryLargeWidth } from "@book-the-play/utils/constant";
import "./styles/faq.scss";

export const Faq = () => {
  return (
    <section id="faq1" className="faq-main-container">
      <div className="main-container">
        <div>
          <div>
            <Typography tag="h2" className="wb-text-[2.4rem] wb-mb-4">
              FAQ's
            </Typography>
          </div>
          <FrequentlyAskQuestionAccordion />
        </div>
      </div>
      <div className="custom-image-block">
        <Image
          width={veryLargeWidth}
          height={veryLargeHeight}
          src="img/demos/app-landing/svg/shape-1.svg"
          alt="shape-1"
        />
      </div>
    </section>
  );
};
