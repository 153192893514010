import React from "react";

export const Reviews = () => {
  return (
    <section id="reviews" className="bg-dark py-5 my-5 p-relative z-index-2">
      <div className="container">
        <div className="row align-items-center py-5">
          <div
            className="col text-center appear-animation"
            data-appear-animation="fadeInRightShorterPlus"
            data-appear-animation-delay={100}
          >
            <h2 className="text-color-light font-weight-bold text-10 mb-4-5">
              Reviews
            </h2>
            <div
              className="owl-carousel owl-theme stage-margin nav-style-1 nav-font-size-lg nav-light"
              data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false, 'stagePadding': 40}"
            >
              <div className="px-5">
                <p className="text-primary text-7">
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                </p>
                <p className="text-4-5 line-height-6 negative-ls-05 text-color-light mb-2">
                  {`I'm so impressed with the convenience and efficiency of Book The Play! As someone who loves playing 
                      sports and games, I always struggled to find available slots at my preferred location 
                      and time. But with Book The Play, I can easily browse a list of available games and book 
                      slots according to my preference without any hassle. What's more, the online payment feature makes 
                      the booking process even more convenient and stress-free. Thanks to Book The Play, I can now enjoy my 
                      favorite games without any trouble.`}{" "}
                </p>
                <p className="text-2 opacity-7 text-color-light">NOV 2020</p>
                <p className="text-4-5 line-height-6 negative-ls-05 font-weight-bold text-color-light mb-0">
                  Raj Doe
                </p>
              </div>
              <div className="px-5">
                <p className="text-primary text-7">
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                </p>
                <p className="text-4-5 line-height-6 negative-ls-05 text-color-light mb-2">
                  I
                  {`'m so impressed with the convenience and efficiency of Book The Play! As someone who loves playing 
                      sports and games, I always struggled to find available slots at my preferred location 
                      and time. But with Book The Play, I can easily browse a list of available games and book 
                      slots according to my preference without any hassle. What's more, the online payment feature makes 
                      the booking process even more convenient and stress-free. Thanks to Book The Play, I can now enjoy my 
                      favorite games without any trouble.`}{" "}
                </p>
                <p className="text-2 opacity-7 text-color-light">NOV 2020</p>
                <p className="text-4-5 line-height-6 negative-ls-05 font-weight-bold text-color-light mb-0">
                  Josh Doe
                </p>
              </div>
              <div className="px-5">
                <p className="text-primary text-7">
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                  <i className="far fa-star" />
                </p>
                <p className="text-4-5 line-height-6 negative-ls-05 text-color-light mb-2">
                  {`I'm so impressed with the convenience and efficiency of Book The Play! As someone who loves playing 
                      sports and games, I always struggled to find available slots at my preferred location 
                      and time. But with Book The Play, I can easily browse a list of available games and book 
                      slots according to my preference without any hassle. What's more, the online payment feature makes 
                      the booking process even more convenient and stress-free. Thanks to Book The Play, I can now enjoy my 
                      favorite games without any trouble.`}{" "}
                </p>
                <p className="text-2 opacity-7 text-color-light">NOV 2020</p>
                <p className="text-4-5 line-height-6 negative-ls-05 font-weight-bold text-color-light mb-0">
                  Monica Doe
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
