import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import {
  extraLargeHeight,
  imageQuality,
  minMediumWidth,
} from "@book-the-play/utils/constant";

interface LeftSectionProps {
  imgPath: string;
  imgAlt: string;
  title: string;
  para1: string;
  para2: string;
}

export const LeftSection = ({
  title,
  para1,
  para2,
  imgAlt,
  imgPath,
}: LeftSectionProps) => {
  return (
    <div className="wb-mt-4 wb-flex wb-relative wb-flex-initial wb-items-center wb-w-full wb-gap-10 wb-justify-end mlg:wb-flex-col-reverse">
      <Transition
        className="wb-text-center wb-flex-initial lg:wb-text-end mlg:wb-text-start "
        variants={DIRECTION_VARIANTS.FROM_RIGHT}
        transition={{
          duration: 0.5,
        }}
      >
        <Typography
          tag="h2"
          className="wb-text-[2.4rem] wb-mb-4 mlg:wb-text-[2rem]"
        >
          {title}
        </Typography>
        <Typography
          tag="p"
          className="wb-text-lg wb-font-medium wb-leading-7 wb-mb-2"
        >
          {para1}
        </Typography>
        <Typography tag="p" className="wb-mb-0 wb-text-base">
          {para2}
        </Typography>
      </Transition>
      <Transition
        className="wb-text-center wb-flex-initial"
        variants={DIRECTION_VARIANTS.FROM_RIGHT}
        transition={{
          duration: 0.5,
        }}
      >
        <Image
          src={imgPath}
          className="wb-mt-4 wb-mb-4 lg:wb-mb-0"
          alt={imgAlt}
          width={minMediumWidth}
          height={extraLargeHeight}
          quality={imageQuality}
        />
      </Transition>
    </div>
  );
};
