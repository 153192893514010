import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import { keyFeaturesDetails } from "@book-the-play/utils/constant";
import { cn } from "@libs/utils/twMerge";

const featureImageWidth = 46;
const featureImageHeight = 46;

interface FeatureListProps {
  startingIndex: number;
  endingIndex: number;
  listShowClassName?: string;
  descriptionBlockClassName?: string;
}

export const FeatureList = ({
  startingIndex,
  endingIndex,
  listShowClassName,
  descriptionBlockClassName,
}: FeatureListProps) => {
  return (
    <div className="wb-w-[50%] mlg:wb-w-full">
      {keyFeaturesDetails
        .slice(startingIndex, endingIndex)
        .map(
          ({
            id,
            title,
            description,
            imagePath,
            imgAlternateName,
            imgClassName,
            animationDelay,
            blockAnimation,
          }) => (
            <>
              <Transition
                className={cn(
                  "md:wb-flex wb-gap-5 wb-items-start wb-mb-10 wb-hidden",
                  listShowClassName,
                )}
                key={id}
                transition={{
                  duration: 0.5,
                  delay: animationDelay,
                }}
                variants={blockAnimation}
              >
                <div className="wb-border-solid wb-bg-transparent wb-leading-[32px] wb-inline-flex wb-items-center wb-justify-center wb-rounded-full wb-relative wb-top-[5px] wb-text-center wb-p-[0.8em] wb-border-2">
                  <Image
                    width={featureImageWidth}
                    height={featureImageHeight}
                    src={imagePath}
                    alt={imgAlternateName}
                    className={imgClassName}
                  />
                </div>
                <div
                  className={cn(
                    "wb-flex wb-flex-col wb-h-[100px]",
                    descriptionBlockClassName,
                  )}
                >
                  <Typography tag="h2" className="wb-text-[1.3rem]">
                    {title}
                  </Typography>
                  <Typography tag="p" className="wb-text-base">
                    {description}
                  </Typography>
                </div>
              </Transition>
              <div
                className={cn(
                  "wb-flex wb-gap-5 wb-items-start wb-mb-10 md:wb-hidden",
                  listShowClassName,
                )}
                key={id}
              >
                <div className="wb-border-solid wb-bg-transparent wb-leading-[32px] wb-inline-flex wb-items-center wb-justify-center wb-rounded-full wb-relative wb-top-[5px] wb-text-center wb-p-[0.8em] wb-border-2">
                  <Image
                    width={featureImageWidth}
                    height={featureImageHeight}
                    src={imagePath}
                    alt={imgAlternateName}
                    className={imgClassName}
                  />
                </div>
                <div
                  className={cn(
                    "wb-flex wb-flex-col wb-h-auto",
                    descriptionBlockClassName,
                  )}
                >
                  <Typography tag="h2" className="wb-text-[1.3rem]">
                    {title}
                  </Typography>
                  <Typography tag="p" className="wb-text-base">
                    {description}
                  </Typography>
                </div>
              </div>
            </>
          ),
        )}
    </div>
  );
};
