import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import {
  extraLargeHeight,
  imageQuality,
  minMediumWidth,
} from "@book-the-play/utils/constant";
import {
  DIRECTION_VARIANTS,
  FADE_TO_SHOW_CIRCLE_ANIMATION,
} from "@libs/utils/animation";
import "./styles/rightSection.scss";

interface RightSectionProp {
  imgPath: string;
  imgAlt: string;
  title: string;
  para1: string;
  para2: string;
}

export const RightSection = ({
  title,
  para1,
  para2,
  imgAlt,
  imgPath,
}: RightSectionProp) => {
  return (
    <div className="wb-pb-5 wb-mb-3 wb-items-center lg:wb-pb-0 lg:wb-mb-0 wb-flex mlg:wb-flex-col">
      <Transition
        className="wb-relative wb-w-[25%] wb-flex-initial wb-text-center mlg:wb-w-full"
        variants={DIRECTION_VARIANTS.FROM_LEFT}
        transition={{
          duration: 0.5,
        }}
      >
        <Transition
          className="custom-el custom-el-circle wb-border-secondary wb-top-[44%] wb-left-[81%] wb-w-[15px] wb-h-[15px]"
          variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
        />
        <Transition
          className="custom-el custom-el-circle custom-el-blur-1 wb-border-primary wb-top-[39%] -wb-left-[15%] wb-w-[48px] wb-h-[48px]"
          variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
        />
        <Transition
          className="custom-el custom-el-rounded-rectangle custom-rotate-45 custom-el-blur-2 wb-border-primary wb-top-[68%] wb-left-[5%] wb-w-[27px] wb-h-[27px]"
          variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
        />
        <Transition
          className="custom-el custom-el-rounded-rectangle custom-rotate-45 custom-el-blur-1 wb-border-primary wb-top-[17%] wb-left-[15%] wb-w-[18px] wb-h-[18px]"
          variants={FADE_TO_SHOW_CIRCLE_ANIMATION}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
        />
        <Transition
          className="wb-text-center wb-flex-initial"
          variants={DIRECTION_VARIANTS.FROM_LEFT}
          transition={{
            duration: 0.5,
          }}
        >
          <Image
            src={imgPath}
            className="wb-mt-4 wb-mb-4 lg:wb-mb-0 wb-ml-2"
            width={minMediumWidth}
            height={extraLargeHeight}
            quality={imageQuality}
            alt={imgAlt}
          />
        </Transition>
      </Transition>
      <Transition
        className="wb-relative wb-w-[75%] wb-flex-initial mlg:wb-w-full"
        variants={DIRECTION_VARIANTS.FROM_LEFT}
        transition={{
          duration: 0.5,
        }}
      >
        <Typography
          tag="h2"
          className="wb-text-[2.4rem] wb-mb-4 mlg:wb-text-[2rem]"
        >
          {title}&nbsp;
        </Typography>
        <Typography
          tag="p"
          className="wb-text-lg wb-font-medium wb-leading-7 wb-tracking-wider wb-mb-2"
        >
          {para1}
        </Typography>
        <Typography tag="p" className="wb-mb-0 wb-text-base">
          {para2}
        </Typography>
      </Transition>
    </div>
  );
};
