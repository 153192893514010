import Link from "next/link";

export const ErrorPage = () => (
  <section className="wb-container wb-m-auto wb-h-[80dvh] wb-flex wb-flex-col wb-justify-center wb-items-center wb-gap-5 wb-text-center wb-px-10">
    <h1 className="wb-text-3xl wb-text-dark wb-font-bold">Oops!</h1>
    <p className="wb-text-lg wb-text-dark-rgba-70">
      We encountered an error and are working to fix it. Please try again later.
    </p>
    <Link
      href="/"
      className="wb-px-5 wb-py-2 wb-border wb-text-primary wb-border-primary hover:wb-text-light hover:wb-bg-primary wb-rounded"
    >
      Homepage
    </Link>
  </section>
);
