import Image from "next/image";
import { Transition } from "@libs/src/components/Transition";
import { Typography } from "@libs/src/components/Typography";
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import {
  extraExtraSmallHeight,
  minSmallHeight,
  minSmallWidth,
  smallWidth,
  verySmallHeight,
  verySmallWidth,
} from "@book-the-play/utils/constant";

interface WhyBecomePartnerProps {
  firstImagePath: string;
  firstImageAlt: string;
  firstTitle: string;
  secondImagePath: string;
  secondImageAlt: string;
  secondTitle: string;
}

export const WhyBecomePartner = ({
  firstImagePath,
  firstImageAlt,
  firstTitle,
  secondImagePath,
  secondImageAlt,
  secondTitle,
}: WhyBecomePartnerProps) => {
  return (
    <div className="wb-pt-16 wb-pb-0 mlg:wb-pt-0">
      <div className="wb-flex wb-justify-center wb-gap-5 mlg:wb-flex-col mlg:wb-items-center">
        <Transition
          className="md:wb-flex wb-flex-col wb-text-center wb-hidden"
          transition={{
            duration: 0.5,
          }}
          variants={DIRECTION_VARIANTS.FROM_LEFT}
        >
          <div className="wb-border-2 wb-p-[14px] wb-my-4 wb-mx-auto wb-rounded-full wb-border-secondary">
            <Image
              width={minSmallWidth}
              height={minSmallHeight}
              src={firstImagePath}
              alt={firstImageAlt}
            />
          </div>
          <Typography
            tag="h4"
            className="wb-text-base wb-tracking-normal wb-w-[170px] wb-text-center wb-flex"
          >
            {firstTitle}
          </Typography>
        </Transition>
        <div className="wb-flex wb-flex-col wb-text-center md:wb-hidden">
          <div className="wb-border-2 wb-p-[14px] wb-my-4 wb-mx-auto wb-rounded-full wb-border-secondary">
            <Image
              width={minSmallWidth}
              height={minSmallHeight}
              src={firstImagePath}
              alt={firstImageAlt}
            />
          </div>
          <Typography
            tag="h4"
            className="wb-text-base wb-tracking-normal wb-w-[170px] wb-text-center wb-flex"
          >
            {firstTitle}
          </Typography>
        </div>
        <Transition
          className="wb-flex wb-flex-col wb-text-center wb-relative wb-min-w-[115px] wb-mt-[15px] mlg:wb-hidden"
          transition={{
            duration: 0.5,
          }}
          variants={DIRECTION_VARIANTS.FROM_LEFT}
        >
          <div className="wb-absolute wb-top-[15%] wb-left-[5px]">
            <Image
              width={smallWidth}
              height={extraExtraSmallHeight}
              src="/img/demos/app-landing/svg/connect-dots.svg"
              alt="connect-dots"
            />
          </div>
          <div className="wb-absolute wb-top-[15%] wb-left-[98px] wb-mt-[15px]">
            <Image
              width={verySmallWidth}
              height={verySmallHeight}
              src="/img/demos/app-landing/svg/connect-point.svg"
              alt="connect-point"
            />
          </div>
        </Transition>
        <Transition
          className="wb-hidden md:wb-block"
          transition={{
            duration: 0.5,
          }}
          variants={DIRECTION_VARIANTS.FROM_LEFT}
        >
          <div className="wb-border-2 wb-p-[14px] wb-my-4 wb-mx-auto wb-rounded-full wb-border-secondary wb-w-[65%]">
            <Image
              width={minSmallWidth}
              height={minSmallHeight}
              src={secondImagePath}
              alt={secondImageAlt}
            />
          </div>
          <Typography
            tag="h4"
            className="wb-text-base wb-tracking-wider wb-w-[170px] wb-text-center wb-flex"
          >
            {secondTitle}
          </Typography>
        </Transition>
        <div className="md:wb-hidden wb-block">
          <div className="wb-border-2 wb-p-[14px] wb-my-4 wb-mx-auto wb-rounded-full wb-border-secondary wb-w-[65%]">
            <Image
              width={minSmallWidth}
              height={minSmallHeight}
              src={secondImagePath}
              alt={secondImageAlt}
            />
          </div>
          <Typography
            tag="h4"
            className="wb-text-base wb-tracking-wider wb-w-[170px] wb-text-center wb-flex"
          >
            {secondTitle}
          </Typography>
        </div>
      </div>
    </div>
  );
};
