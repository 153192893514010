import React from "react";

interface Props {
  html: string;
  className?: string;
}

const SafeHTMLRenderer = ({ html, className }: Props) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: html }} className={className} />
  );
};

export default SafeHTMLRenderer;
