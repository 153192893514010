import Image from "next/image";
import Link from "next/link";
import { Typography } from "@libs/src/components/Typography";
import {
  bookThePlayStoreButton,
  extraSmallHeight,
  extraSmallWidth,
} from "@book-the-play/utils/constant";

export const Playstore = () => {
  return (
    <section className="wb-mb-10 bg-gradient-custom call-to-action-new custom-call-to-action">
      <div className="wb-w-full">
        <div className="wb-p-6 wb-flex md:wb-p-0 mlg:wb-flex-col mlg:wb-gap-10">
          <div className="wb-w-[58.33%] wb-flex-initial flex-center mlg:wb-w-full">
            <div className="wb-mx-auto lg:wb-m-0 wb-text-center">
              <Typography
                tag="h3"
                className="wb-text-light wb-capitalize wb-tracking-wide"
              >
                Tired of the Hustle & bustle of our busy life?
              </Typography>
              <Typography
                tag="p"
                className="wb-opacity-[7] wb-text-light wb-capitalize wb-tracking-wide wb-text-base wb-pt-2"
              >
                Hit pause to break free, step outside and discover the thrill of
                Play.
              </Typography>
            </div>
          </div>
          <div className="wb-text-center lg:wb-text-start wb-w-[41.66%] wb-flex-initial mlg:wb-w-full">
            <div className="flex-center wb-gap-3 wb-px-6 wb-flex-col md:wb-flex-row mmd:wb-gap-5">
              {/* Note: for future use
              
              <div className="px-3 d-inline-flex align-items-center btn btn-outline text-uppercase positive-ls-2 font-weight-bold text-2 btn-py-3 px-sm-5 download-btn">
                <span className="d-none d-sm-inline-block">
                  <img
                    width={32}
                    height={28}
                    src="/img/demos/app-landing/icons/icon-cloud.svg"
                    alt=""
                    data-icon=""
                    data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-stroke-color-secondary me-2'}"
                  />
                </span>
                Download Now
              </div> */}
              {bookThePlayStoreButton.map(
                ({ id, alternateName, srcPath, storeName, socialLink }) => {
                  return (
                    <Link
                      className="play-store-btn"
                      href={socialLink}
                      target="_blank"
                      key={id}
                    >
                      <Image
                        height={extraSmallWidth}
                        width={extraSmallHeight}
                        src={srcPath}
                        alt={alternateName}
                      />
                      <Typography
                        tag="span"
                        className="wb-font-semibold wb-text-base wb-flex wb-flex-col"
                      >
                        <Typography
                          tag="span"
                          className="wb-uppercase wb-font-medium wb-opacity-[0.5] wb-block wb-relative wb-text-xs"
                        >
                          Download On
                        </Typography>
                        <Typography
                          tag="span"
                          className="wb-text-dark wb-relative wb-text-xl"
                        >
                          {storeName}
                        </Typography>
                      </Typography>
                    </Link>
                  );
                },
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
