import Link from "next/link";
import Image from "next/image";
import {
  footerSocialLinks,
  iconHeight,
  iconWidth,
} from "@book-the-play/utils/constant";
import { routes } from "@book-the-play/utils/route";
import { Typography } from "@libs/src/components/Typography";
import "./style/footer.scss";

export const Footer = () => (
  <footer className="main-container footer-section">
    <div className="wb-flex wb-flex-col wb-gap-2">
      <Typography tag="p">
        Book The Play © Copyright {new Date().getFullYear()}.
      </Typography>
      <Typography tag="p">
        All Rights Reserved. <span> | </span>{" "}
        <Link href={routes.privacyPolicy} className="hover:wb-text-primary">
          Privacy Policy
        </Link>
      </Typography>
      <Typography tag="p">
        <Link
          href={routes.refundCancellation}
          className="hover:wb-text-primary"
        >
          Refund Cancellation
        </Link>{" "}
        <span> | </span>
        <Link href={routes.termsCondition} className="hover:wb-text-primary">
          Terms conditions
        </Link>
      </Typography>
      <Typography tag="p">
        <span>Powered by</span>{" "}
        <Link
          href={routes.webelightWebSite}
          target="_blank"
          className="hover:wb-text-primary"
        >
          Webelight Solutions Private Limited
        </Link>
      </Typography>
    </div>

    <div className="flex-center wb-gap-4 wb-text-base">
      {footerSocialLinks.map(
        ({ title, imagePath, imgAlt, path, className }) => (
          <Link
            key={title}
            href={path}
            className="social-links"
            target="_blank"
            title={title}
          >
            <Image
              src={imagePath}
              alt={imgAlt}
              width={iconWidth}
              height={iconHeight}
              className={className}
            />
          </Link>
        ),
      )}
    </div>
  </footer>
);
