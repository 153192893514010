import SafeHTMLRenderer from "../SafeHTMLRenderer";
import {
  YoutubeVideoUrl,
  oembedUrlPattern,
  replacementOfOembedUrlPattern,
} from "@book-the-play/utils/constant";
import "./style/YouTubeVideoSection.scss";

export const YouTubeVideoSection = () => {
  const oembedUrl = YoutubeVideoUrl.replace(
    oembedUrlPattern,
    replacementOfOembedUrlPattern,
  );

  const iframeElement = `<iframe class="video-size" loading="lazy" src="${oembedUrl}" frameborder="0" allow="accelerometer; 
  autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  return (
    <div className="youtube-section flex-center">
      <SafeHTMLRenderer html={iframeElement ?? ""} />
    </div>
  );
};
