import { Typography } from "@libs/src/components/Typography";
import { RightSection } from "./RightSection";
import { LeftSection } from "./LeftSection";
import { BackgroundImage } from "./BackgroundImage";
import "./styles/howItWorks.scss";

export const HowItWorks = () => {
  return (
    <section className="how-it-works-section" id="how-it-works">
      <Typography tag="h2" className="main-title">
        How it Works
      </Typography>
      <div className="main-container wb-my-10">
        <RightSection
          imgPath="/img/BTP-moklups-mobile/1-signup.png"
          imgAlt="1-signup"
          title="Quick & Easy Onboarding"
          para1="Join the vibrant Book The Play App with just a few clicks."
          para2="Our user-friendly check-ins reduce friction & accelerates the onboarding process."
        />
        <LeftSection
          imgPath="/img/BTP-moklups-mobile/2-game-list.png"
          imgAlt="2-game-list"
          title="Discover Your Playground"
          para1="Open Book The Play app and explore a diverse range of sports facilities, fitness centers, and recreational spots."
          para2="Choose from your preferred location and type of sport."
        />
      </div>
      <BackgroundImage />
      <div className="main-container">
        <div className="right-main-section">
          <RightSection
            imgPath="/img/BTP-moklups-mobile/3-selection.png"
            imgAlt="3-selection"
            title="Find Your Match"
            para1="Click on a venue to discover more details, including facilities,reviews, and photos."
            para2="Check real-time availability, pricing, and any exclusive offers."
          />
        </div>
        <LeftSection
          imgPath="/img/BTP-moklups-mobile/4-success.png"
          imgAlt="4-success"
          title="Reserve Your Spot"
          para1="Once you've found the perfect spot, select your preferred date and time."
          para2="Confirm your booking details and proceed to the easy-to-use payment page."
        />
      </div>
      <BackgroundImage />
    </section>
  );
};
