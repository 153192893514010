import { Downloads } from "./components/Downloads";
import { Faq } from "./components/Faq";
import { Header } from "./components/Header";
import { HowItWorks } from "./components/HowItWork";
import { KeyFeatures } from "./components/KeyFeatures";
import { Overview } from "./components/Overview";
import { BecomeAPartner, PartnerForm } from "./components/BecomeAPartner";
import { Reviews } from "./components/Reviews";
import Modal from "./components/Modal";
import { ContactUs } from "./components/ContactUs";
import { Footer } from "./components/Footer";
import { Loading } from "./components/Loading";
import { ErrorPage } from "./components/ErrorPage";
import { YouTubeVideoSection } from "./components/YouTubeVideo/YouTubeVideoSection";

export {
  Downloads,
  Faq,
  Header,
  HowItWorks,
  KeyFeatures,
  Overview,
  BecomeAPartner,
  Reviews,
  PartnerForm,
  Modal,
  ContactUs,
  Footer,
  Loading,
  ErrorPage,
  YouTubeVideoSection,
};
